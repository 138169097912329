<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.542114 9.85942C0.542114 6.08453 0.542114 4.19708 1.44164 2.8376C1.84332 2.23053 2.36322 1.71063 2.97029 1.30895C4.32977 0.409424 6.21722 0.409424 9.99211 0.409424C13.767 0.409424 15.6545 0.409424 17.0139 1.30895C17.621 1.71063 18.1409 2.23053 18.5426 2.8376C19.4421 4.19708 19.4421 6.08453 19.4421 9.85942C19.4421 13.6343 19.4421 15.5218 18.5426 16.8812C18.1409 17.4883 17.621 18.0082 17.0139 18.4099C15.6545 19.3094 13.767 19.3094 9.99211 19.3094C6.21722 19.3094 4.32977 19.3094 2.97029 18.4099C2.36322 18.0082 1.84332 17.4883 1.44164 16.8812C0.542114 15.5218 0.542114 13.6343 0.542114 9.85942ZM14.8843 9.85965C14.8843 12.5616 12.694 14.752 9.99197 14.752C7.28998 14.752 5.09958 12.5616 5.09958 9.85965C5.09958 7.15767 7.28998 4.96727 9.99197 4.96727C12.694 4.96727 14.8843 7.15767 14.8843 9.85965ZM9.99197 13.0968C11.7798 13.0968 13.2291 11.6475 13.2291 9.85965C13.2291 8.07182 11.7798 6.6225 9.99197 6.6225C8.20413 6.6225 6.75481 8.07182 6.75481 9.85965C6.75481 11.6475 8.20413 13.0968 9.99197 13.0968ZM15.0776 5.8711C15.7125 5.8711 16.2271 5.35642 16.2271 4.72154C16.2271 4.08665 15.7125 3.57197 15.0776 3.57197C14.4427 3.57197 13.928 4.08665 13.928 4.72154C13.928 5.35642 14.4427 5.8711 15.0776 5.8711Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconInstagram'
}
</script>
